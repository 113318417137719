import React, { useState } from 'react'
import { Link, graphql } from 'gatsby';
import globalStyle from '../components/css_modules/global.module.scss';
import blogMain from '../components/css_modules/blog/blogmain.module.scss';
import Layout from '../components/layout';

export default function Template({ data }) {

    const post = data.markdownRemark;

    return (
        <Layout>
            <section className={blogMain.mainSectionBlogSinglePost}>
                <div className={globalStyle.container}>
                    <div className={blogMain.featuredImage}>
                        <img src={post.frontmatter.featuredImg}></img>
                    </div>
                    <div className={blogMain.blogPostWrapper}>
                        <div className={blogMain.postContent}>
                            <h1>{post.frontmatter.title}</h1>
                            <p><small>Written by {post.frontmatter.author} on {post.frontmatter.date}</small></p>
                            <div className={blogMain.postMainContent} dangerouslySetInnerHTML={{ __html: post.html }} />
                            {/* <Link to="/blog">Back to blog posts</Link> */}
                        </div>
                        <div className={blogMain.sidebar}>
                            <ul className={blogMain.sidebarElements}>
                                <li className={blogMain.sidebarSection}>
                                    <div className={blogMain.featuredPosts}>
                                        <h2 className={blogMain.sidebarTitles}>You might also like</h2>
                                        <ul>
                                            <li><a href="/blog/how-build-digital-community-part-1-setting-strategy-success">How to build a digital community Part 1: Setting the strategy for success right</a></li>
                                            <li><a href="/blog/youve-got-strategy-now-its-time-make-work">How to build a digital community Part 2: You've got the strategy, now its time to make it work</a></li>
                                            <li><a href="/blog/digital-product-survive-without-community">No digital product will survive without a digital community</a></li>
                                            <li><a href="/blog/how-bring-value-your-audience">How to bring value to your audience</a></li>
                                            <li><a href="/blog/why-you-need-align-your-marketing-teams">Why you need to align your IT and Marketing teams</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className={blogMain.sidebarSection}>
                                    <div className={blogMain.sidebarContact}>
                                        <h2>Get in touch</h2>
                                        <p>Get in touch to find out how we can help your business prepare for the future!</p>
                                        <div className={blogMain.sidebarContactButton}><a className={globalStyle.mainButton} href="/contact">Contact us</a></div>
                                    </div>
                                </li>
                                <li className={blogMain.sidebarSection}>
                                    <h2>Stay Updated</h2>
                                    <p>Be the first to receive our latest and greatest insights</p>
                                    {/* Begin Mailchimp Signup Form */}
                                    <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
                                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc_embed_signup{}\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
                                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: 250px;margin-right: 10px;}\n\t#mergeRow-gdpr {margin-top: 20px;}\n\t#mergeRow-gdpr fieldset label {font-weight: normal;}\n\t#mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;border-radius:0; outline: none;}\n" }} />
                                    <div id="mc_embed_signup">
                                        <form action="https://rocks.us5.list-manage.com/subscribe/post?u=b3ac922c4afd7563fb0723e94&amp;id=2496565300" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                            <div id="mc_embed_signup_scroll">
                                                {/* <input type="email" defaultValue name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required /> */}
                                                <input type="email" name="EMAIL" className="email blog-email" id="mce-EMAIL" placeholder="Email address" required />
                                                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_b3ac922c4afd7563fb0723e94_2496565300" tabIndex={-1} defaultValue /></div>
                                                <div className="clear"><input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={globalStyle.mainButton} /></div>
                                            </div>
                                        </form>
                                    </div>
                                    {/*End mc_embed_signup*/}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export const postQuery = graphql`
query BlogPostByPath($path: String!) {
                markdownRemark(frontmatter: {path: {eq: $path}}){
                html
        frontmatter {
                path
            title
                author
                date
                featuredImg
                previewContent
            }
        }
    }
`